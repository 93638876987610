.swiper-pagination-bullet {
  width: 8px !important;
  height: 8px !important;
  border-radius: 4px !important;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
  transition: width 0.2s;
}

.swiper-pagination-bullet-active {
  width: 30px !important;
  height: 8px !important;
  color: #fff;
  background: #df3e23 !important;
  transition: width 0.2s;
}

/* .swiper-pagination {
    width: 100px !important;
} */
.content-news * {
  max-width: 100%;
  font-family: 'Montserrat' !important;
}

.content-news img {
  padding: 15px 0;
}

.icon-12 {
  display: inline-block;
  width: 12px;
  height: 12px;
}
.icon-home {
  background: url(/public/images/icon/home.svg) no-repeat;
}
.icon-hop-tac {
  background: url(/public/images/icon/hop-tac.svg) no-repeat;
}

.icon-wiki {
  background: url(/public/images/icon/wiki.svg) no-repeat;
}
.icon-mail {
  background: url(/public/images/icon/mail.svg) no-repeat;
}
